import React, { useContext, useEffect, useMemo, useState } from "react";
import style from "./style.module.scss";
import SelectField from "Components/FormElements/Select";
import { useCheckAppHook } from "Hooks/useCheckApp.hook";
import { CONSTANTS } from "consts";
import { selectWorkoutUnit } from "contexts/profileWorkout";
import { UserAppContext } from "contexts";

const MeasurementUnit = () => {
    const { appId, measureUnit } = useCheckAppHook();
    const { workoutState, workoutDispatch } = useContext(UserAppContext);
    const [selectedUnit, setSelectedUnit] = useState('miles');
    
    const handleSelectUnit = (event: any) => {
      setSelectedUnit(event.target.value)
      localStorage.setItem("measureUnit", event.target.value);
      workoutDispatch(selectWorkoutUnit(event.target.value))
    }

    useEffect(() => {
      if (appId === CONSTANTS.APP.AVIRON_BIKE || appId === CONSTANTS.APP.AVIRON_TREADMILL) {
        //   workoutDispatch(selectWorkoutUnit(measureUnit));
        //   localStorage.setItem("measureUnit", measureUnit);
          setSelectedUnit(measureUnit)
      }
  }, [appId, measureUnit]);
    return (
        <div className={style["MeasurementUnit"]}>
            <div className={style["name"]}>Unit of measurement</div>
            <SelectField
                // @ts-ignore
                options={[
                    { name: "MPH", value: "miles" },
                    { name: "KPH", value: "kilometers" },
                ]}
                value={selectedUnit}
                onChange={handleSelectUnit}
            />
        </div>
    );
};

export default MeasurementUnit;
