import { AvironProductTypes } from "../consts/order";
import { OrderLine } from "../services/vendure";

export function isAccessoryOrderLine(line: OrderLine) {
    return (
        line.productVariant?.customFields?.type === AvironProductTypes.ACCESSORY
    );
}

export function isSubMembershipOrderLine(line: OrderLine) {
    return line.productVariant?.customFields?.type?.includes(
        AvironProductTypes.SUB_MEMBERSHIP
    );
}

export function isNormalWarrantyOrderLine(line: OrderLine) {
    return (
        line.productVariant?.customFields?.type === AvironProductTypes.WARRANTY
    );
}

export function isGoWarrantyOrderLine(line: OrderLine) {
    return (
        line.productVariant?.customFields?.type ===
        AvironProductTypes.GO_WARRANTY
    );
}

export function isWarrantyOrderLine(line: OrderLine) {
    return isNormalWarrantyOrderLine(line) || isGoWarrantyOrderLine(line);
}

export function isShippingServiceOrderLine(line: OrderLine) {
    return line.productVariant?.customFields?.type?.includes(
        AvironProductTypes.SHIPPING_SERVICE
    );
}

export function isFreeTrialMembershipIncludedOrderLine(line: OrderLine) {
    return Boolean(line.productVariant?.customFields?.freeTrialMembership);
}

// export function isStrongGoOrderLine(line: OrderLine) {
//     return (
//         parseInt(line.productVariant?.product?.id, 10) ===
//         parseInt(ENV.PRODUCT.GO.PRODUCT_ID, 10)
//     );
// }

// export function isNormalMachineOrderLine(line: OrderLine) {
//     return (
//         line.productVariant?.customFields?.type?.includes(
//             AvironProductTypes.MACHINE
//         ) && !isStrongGoOrderLine(line)
//     );
// }

// export function isMachineOrderLine(line: OrderLine) {
//     return isNormalMachineOrderLine(line) || isStrongGoOrderLine(line);
// }

export function isBikeMachineOrderLine(line: OrderLine) {
    return (
        line.productVariant?.customFields?.type ===
        AvironProductTypes.BIKE_MACHINE
    );
}

export function isFitPlanMachineOrderLine(line: OrderLine) {
    return (
        line.productVariant?.customFields?.type ===
        AvironProductTypes.FITPLAN_MACHINE
    );
}

export function isNormalMembershipOrderLine(line: OrderLine) {
    return (
        line.productVariant?.customFields?.type ===
        AvironProductTypes.MEMBERSHIP
    );
}

export function isNormalSubMembershipOrderLine(line: OrderLine) {
    return (
        line.productVariant?.customFields?.type ===
        AvironProductTypes.SUB_MEMBERSHIP
    );
}

export function isGoMembershipOrderLine(line: OrderLine) {
    return (
        line.productVariant?.customFields?.type ===
        AvironProductTypes.GO_MEMBERSHIP
    );
}

export function isGoSubMembershipOrderLine(line: OrderLine) {
    return (
        line.productVariant?.customFields?.type ===
        AvironProductTypes.GO_SUB_MEMBERSHIP
    );
}

export function isBikeSubMembershipOrderLine(line: OrderLine) {
    return (
        line.productVariant?.customFields?.type ===
        AvironProductTypes.BIKE_SUB_MEMBERSHIP
    );
}
