import useGetCrmSessionHook from "Hooks/CrmSessionHook";
import { CONSTANTS } from "consts";
import { Action, UserAppContext } from "contexts";
import { useContext, useEffect } from "react";
import {
    useCheckStravaConnectedMutation,
} from "services/crmServer";
import { getUrlVars } from "utils/url";
import {
    useHandleGetWorkoutList,
} from "../handleActions";

export const useMainHook = () => {
    const { crmToken } = useGetCrmSessionHook();
    const [checkStravaConnected] = useCheckStravaConnectedMutation();
    const { handleGetWorkoutList } = useHandleGetWorkoutList();

    const { WORKOUT_QUERY_RANGE, PROFILE_PAGE } = CONSTANTS;
    const {
        auth,
        workoutState,
        authDispatch,
        workoutDispatch,
        profileDispatch,
        timezone,
    } = useContext(UserAppContext);

    const { workoutMetrics, loading, currentSelect, workoutDetail } =
        workoutState;
    console.log('lplplpllplp===', workoutMetrics);
    const appId = localStorage.getItem('appId');
    const isAvironTreadmill = appId === String(CONSTANTS.APP.AVIRON_TREADMILL);
    const formatWorkoutMetric = (workoutMetrics: any) => {
        if(isAvironTreadmill) {
            return {
                ...workoutMetrics,
                avgSPM: workoutMetrics.avgSecp500
            }
        }
        return workoutMetrics
    }
    const timeZone = timezone.timeZone;
    useEffect(() => {
        if (auth && auth.crmUserId && crmToken) {
            checkStravaConnected({
                context: {
                    clientName: "crm-server",
                    headers: {
                        authorization: "Bearer " + crmToken,
                    },
                },
            }).then((result) => {
                if (result && result.data) {
                    authDispatch(Action.auth.stravaConnected(true));
                }
            });
        }
    }, [auth, crmToken]);

    const params = getUrlVars();

    useEffect(() => {
        // For showing active tab
        profileDispatch(
            Action.profile.setState({
                current_tab: PROFILE_PAGE.CURRENT_TAB.PROFILE_WORKOUT,
            })
        );
        // get initial data
        // && auth.id
        if (auth && crmToken) {
            handleGetWorkoutList(
                {
                    timeRange: WORKOUT_QUERY_RANGE.QUERY_RANGE_ALL,
                    page: Number(params.page) || 1,
                    userData: auth,
                    crmToken: crmToken as string,
                    timeZone,
                },
                workoutDispatch
            );
        }
    }, [auth, crmToken, timeZone]);


    const handleSelectTimeRange = (event: any) => {
        const timeRange = event.target.value;
        if (crmToken) {
            handleGetWorkoutList(
                { timeRange, page: 1, userData: auth, crmToken, timeZone },
                workoutDispatch
            );
        }
    };


    return {
        loading,
        currentSelect,
        workoutMetrics: formatWorkoutMetric(workoutMetrics),
        workoutDetail,
        handleSelectTimeRange,
    };
};
