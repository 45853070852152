import React from "react";
import style from "./CompareStats.module.scss";
import CardSimple from "Components/CardSimple";
import CompareTable from "./CompareTable";
import Select from "Components/FormElements/Select";
import { useCheckAppHook } from "Hooks/useCheckApp.hook";
import { CONSTANTS } from "consts";
import AvironBikeCompareTable from "./AvironBikeCompareTable ";
import AvironTreadmillCompareTable from "./AvironTreadmillCompareTable";

const CompareStats = ({
    summary,
    handleChangeSelect,
    selectValue,
    timeOptions,
}: any) => {
    const { appId } = useCheckAppHook();
    const renderCompareTable = (appId: number) => {
        if (appId === CONSTANTS.APP.AVIRON_BIKE) {
            return (
                <AvironBikeCompareTable
                    summaryTable={{ ...summary }}
                    nameHeaderRow={timeOptions[selectValue].headerTable}
                    selected={timeOptions[selectValue].label}
                />
            );
        } else if (appId === CONSTANTS.APP.AVIRON_TREADMILL) {
            return (
                <AvironTreadmillCompareTable
                    summaryTable={{ ...summary }}
                    nameHeaderRow={timeOptions[selectValue].headerTable}
                    selected={timeOptions[selectValue].label}
                />
            );
        } else {
            return (
                <CompareTable
                    summaryTable={{ ...summary }}
                    nameHeaderRow={timeOptions[selectValue].headerTable}
                    selected={timeOptions[selectValue].label}
                />
            );
        }
    };
    return (
        <div className={style["CompareStatsWrapper"]}>
            <CardSimple
                title={
                    <div className={style["titleCardWrapper"] + " titleCard"}>
                        <div className={style["titleCard"]}>
                            COMPARE STATISTICS
                        </div>
                        <div className={style["select"]}>
                            <Select
                                options={timeOptions.map(
                                    ({ value, name }: any) => ({
                                        name: name,
                                        value: value,
                                    })
                                )}
                                fullWidth
                                value={selectValue}
                                onChange={handleChangeSelect}
                            />
                        </div>
                    </div>
                }
            >
                {renderCompareTable(appId)}
            </CardSimple>
        </div>
    );
};

export default CompareStats;
