import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import GoBack from "Components/GoBack";
import { AvironLoading } from "Components/Icons/AvironLoading";
import PageNotFound from 'Components/NotFoundPage';
import { UserAppContext } from "contexts";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import {
    useGetOrderByCodeAndEmailQuery,
    useGetTrackingOrderStatusQuery,
} from "services/vendure";
// import AddressBox from './AddressBox';
// import MethodBox from './MethodBox';
// import OrderInformation from './OrderInformation';
// import OrderSummary from './OrderSummary';
// import TrackingSection from "./TrackingSection";
import { Helmet } from "react-helmet";
import loadable from "@react-loadable/revised";
import OneLastStep from "./OneLastStep";
import { ENV } from "scripts/environment";
import { isFreeTrialMembershipIncludedOrderLine, isGoMembershipOrderLine, isNormalMembershipOrderLine } from "../../../utils/order";

const TrackingSection = loadable({
    loader() {
        return import("./TrackingSection");
    },
    loading: AvironLoading,
});

const OrderSummary = loadable({
    loader() {
        return import("./OrderSummary");
    },
    loading: AvironLoading,
});

const OrderInformation = loadable({
    loader() {
        return import("./OrderInformation");
    },
    loading: AvironLoading,
});

const MethodBox = loadable({
    loader() {
        return import("./MethodBox");
    },
    loading: AvironLoading,
});

const AddressBox = loadable({
    loader() {
        return import("./AddressBox");
    },
    loading: AvironLoading,
});

// const PageNotFound = loadable({
//     loader() {
//         return import("Components/NotFoundPage");
//     },
//     loading: AvironLoading,
// });

export default function OrderDetail() {
    const { auth } = React.useContext(UserAppContext);
    const params: any = useParams();
    const location = useLocation();
    const { data, loading, error } = useGetOrderByCodeAndEmailQuery({
        variables: {
            code: params.id,
            email: auth.email || "",
        },
        context: { clientName: "vendure" },
    });

    const {
        data: trackData,
        loading: trackLoading,
        error: trackError,
    } = useGetTrackingOrderStatusQuery({
        variables: { code: params.id },
        context: { clientName: "vendure" },
    });

    const orderLines = React.useMemo(() => {
        return get(data, "getOrderByCodeAndEmail.lines", []);
    }, [data]);
    const getProdType = (item: any) =>
        get(item, "productVariant.customFields.type", "");

    const isOnlyMembership = orderLines.every((item: any) => {
        const prodType = getProdType(item);
        return (
            prodType === "membership" || prodType === "subproduct-membership" ||
            prodType === "go-membership" || prodType === "go-subproduct-membership"
        );
    });

    const isHaveMembership = orderLines.some(
        (item: any) => {
            const isNormalMembership  = isNormalMembershipOrderLine(item);
            const isGoMembership = isGoMembershipOrderLine(item);
            const isFreeMembershipIncluded = isFreeTrialMembershipIncludedOrderLine(item) && data?.getOrderByCodeAndEmail?.customFields?.canGetFreeTrialMembership

            return (isFreeMembershipIncluded || (isGoMembership || isNormalMembership) && item?.productVariant?.customFields?.avironPlanId)
        }
    );

    const isHaveFitPlan = orderLines.some((item: any) => item?.productVariant?.customFields?.type === 'fitplan-machine')
    const isHaveGoWarranty = orderLines.some(
        (item: any) =>
            item?.productVariant?.customFields?.type === 'go-warranty' && item?.productVariant?.customFields?.avironPlanId
    )

    const isNotPayMembership = !get(data, "getOrderByCodeAndEmail.customFields.isPayMembership") && (isHaveMembership || isHaveGoWarranty || isHaveFitPlan);

    const orderData = orderLines
        .filter((item: any) => getProdType(item) !== "subproduct-membership" && getProdType(item) !== "go-subproduct-membership")
        .map((item: any) => ({
            quantity: item.quantity,
            name: item.productVariant.name.toUpperCase(),
            price: item.linePrice / 100,
        }));

    // Discount code and amount
    const discounts = get(data, "getOrderByCodeAndEmail.discounts", []);
    const discount = isEmpty(discounts)
        ? null
        : { code: discounts[0].description, amount: discounts[0].amount / 100 };

    // Shipping lines and method

    const shippingLines = get(
        data,
        "getOrderByCodeAndEmail.shippingLines",
        undefined
    );

    const shippingMethod = {
        name: data?.getOrderByCodeAndEmail?.groupedShippingMethods?.[0]?.name || get(shippingLines?.[0], "shippingMethod.name", ""),
        amount: get(data, "getOrderByCodeAndEmail.shipping", 0) / 100,
        code: data?.getOrderByCodeAndEmail?.groupedShippingMethods?.[0]?.name || get(shippingLines?.[0], "shippingMethod.code", "")
    }

    // Tax price
    const taxSummary = get(
        data,
        "getOrderByCodeAndEmail.taxSummary",
        []
    ).reduce((sum: number, item: any) => item.taxTotal + sum, 0);
    const tax = taxSummary / 100;

    // Sub total and total price
    const subTotal = get(data, "getOrderByCodeAndEmail.subTotal", 0) / 100;
    const total = get(data, "getOrderByCodeAndEmail.totalWithTax", 0) / 100;
    const depositedAmount = get(data, "getOrderByCodeAndEmail.customFields.depositedAmount", 0) ? get(data, "getOrderByCodeAndEmail.customFields.depositedAmount", 0) / 100 : undefined;

    const isGloveDelivery = shippingMethod?.code === "glove-delivery"

    const orderInfo = {
        orderCode: get(data, "getOrderByCodeAndEmail.code", ""),
        emailAddress: get(
            data,
            "getOrderByCodeAndEmail.customer.emailAddress",
            ""
        ),
        dateTime: moment(
            get(data, "getOrderByCodeAndEmail.orderPlacedAt", "")
        ).format("DD MMMM YYYY")
    };

    const getPaymentMethod = (paymentMethods: any[]) => {
        const paymentMethod = paymentMethods.find((item) => {
            return item.state === "Settled";
        });
        if (!paymentMethod) return "";
        switch (paymentMethod.method) {
            case "stripe-payment":
                return "Credit Card";
            case "affirm-payment":
                return "Affirm: Pay Over Time";
            case "klarna-payment":
                return "Klarna";
            case "express-payment":
                return "Express payment";
            case "paypal-payment":
                return "Paypal";
            default:
                return "";
        }
    };

    if (loading) {
        return (
            <div className="height-loading">
                <AvironLoading />
            </div>
        )
    }
    if (!loading && error) {
        return <PageNotFound />;
    }
    return (
        <Box
            padding={{ xs: "16px 32px", sm: "0", md: "0", lg: "0 88px" }}
            sx={{ maxWidth: { md: "100%", lg: "768px" } }}
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Order detail | Aviron</title>
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Box marginBottom="45px">
                <GoBack url={location?.pathname} />
            </Box>
            <Paper sx={{ padding: "16px", position: "relative" }}>
                {isNotPayMembership && <OneLastStep checkoutMembershipLink={`${ENV.STOREFRONT_DOMAIN}/checkout/membership/${orderInfo.orderCode}?email=${orderInfo.emailAddress}`} />}
                <OrderInformation orderInfo={orderInfo} />
                <Divider
                    sx={{
                        position: "absolute",
                        left: 0,
                        width: "100%",
                        margin: "8px 0",
                    }}
                />
                <Box
                    display="grid"
                    columnGap="32px"
                    rowGap="0px"
                    marginTop="0px"
                    gridTemplateColumns={{
                        sx: "1fr",
                        sm: "1fr",
                        md: "repeat(2, 1fr)",
                    }}
                >
                    {!isOnlyMembership && (
                        <AddressBox
                            title="SHIPPING ADDRESS"
                            address={get(
                                data,
                                "getOrderByCodeAndEmail.shippingAddress"
                            )}
                        />
                    )}
                    <AddressBox
                        title="BILLING ADDRESS"
                        address={get(
                            data,
                            "getOrderByCodeAndEmail.billingAddress"
                        )}
                    />
                    {isOnlyMembership && <div></div>}
                    <MethodBox
                        title="PAYMENT METHOD"
                        methodLabel="Name"
                        methodValue={getPaymentMethod(
                            get(data, "getOrderByCodeAndEmail.payments", [])
                        )}
                    />
                    {!isOnlyMembership && !isEmpty(shippingLines) && (
                        <MethodBox
                            title="SHIPPING METHOD"
                            methodLabel="Name"
                            methodValue={shippingMethod?.name}
                        />
                    )}
                </Box>
                <Divider
                    sx={{
                        position: "absolute",
                        left: 0,
                        width: "100%",
                        marginTop: "8px",
                    }}
                />
                <OrderSummary
                    orderData={orderData}
                    discount={discount}
                    shippingMethod={shippingMethod}
                    tax={tax}
                    subTotal={subTotal}
                    total={total}
                    depositedAmount={depositedAmount}
                />
                {total > 0 && (
                    <>
                        <Divider
                            sx={{
                                position: "absolute",
                                left: 0,
                                width: "100%",
                                marginTop: "8px",
                            }}
                        />
                        <TrackingSection
                            trackingData={trackData}
                            trackingLoading={trackLoading}
                            trackingError={trackError}
                            isGloveDelivery={isGloveDelivery}
                        />
                    </>
                )}
            </Paper>
        </Box>
    );
}
