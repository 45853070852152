import React, { useContext, useMemo } from "react";
import style from "./AvironBikeCompareTable.module.scss";
import Table from "Components/base/Table";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
    convertedSecondToTime,
    convertedSecondToTimeV2 as formatTime,
} from "consts/dateTime";
import useWindowSize from "Hooks/useWindowSize";
import { CONSTANTS } from "consts";
import { UserAppContext } from "contexts";
import { useCheckAppHook } from "Hooks/useCheckApp.hook";
import {
    convertKilometerToMile,
    convertMeterToFit,
    convertMeterToKilometer,
    convertMeterToMile,
} from "utils/convertMeasure";

interface fieldAPI {
    workouts: number | 0;
    meters: number | 0;
    time: number | 0;
    calories: number | 0;
    outputs: number | 0;
    strokes: number | 0;
    avgCal: number | 0;
    avgSpm: number | 0;
    avg500m: number | 0;
    avgWatt: number | 0;
    maxRpm: number | 0;
    avgSpeed?: number | 0;
    elevationGain: number | 0;
    avgIncline: number | 0;
}
interface compareTableProps {
    summaryTable: {
        different: fieldAPI;
        thisPeriod: fieldAPI;
        lastPeriod: fieldAPI;
    };
    nameHeaderRow: Array<any>;
    selected: any;
}
const AvironBikeCompareTable = ({
    summaryTable,
    nameHeaderRow,
    selected,
}: compareTableProps) => {
    const { workoutState } = useContext(UserAppContext);
    const { appId } = useCheckAppHook();
    const {
        different: differentBase,
        thisPeriod: thisPeriodBase,
        lastPeriod: lastPeriodBase,
    } = summaryTable;
    const different: fieldAPI = {
        ...differentBase,
        avg500m:
            workoutState?.currentUnit === "miles"
                ? differentBase?.avg500m /
                  CONSTANTS.UNIT_CONVERT.KILOMETER_CONVERT_TO_MILE_VALUE
                : differentBase?.avg500m,
        elevationGain:
            workoutState?.currentUnit === "miles"
                ? differentBase?.elevationGain < 0
                    //Process correct Math.floor with -[number]
                    ? -convertMeterToFit(Math.abs(differentBase?.elevationGain))
                    : convertMeterToFit(differentBase?.elevationGain)
                : differentBase?.elevationGain,
    };
    const thisPeriod: fieldAPI = {
        ...thisPeriodBase,
        avg500m:
            workoutState?.currentUnit === "miles"
                ? thisPeriodBase?.avg500m /
                  CONSTANTS.UNIT_CONVERT.KILOMETER_CONVERT_TO_MILE_VALUE
                : thisPeriodBase?.avg500m,
        elevationGain:
        workoutState?.currentUnit === "miles"
            ? convertMeterToFit(thisPeriodBase?.elevationGain)
            : thisPeriodBase?.elevationGain,
    };
    const lastPeriod: fieldAPI = {
        ...lastPeriodBase,
        avg500m:
            workoutState?.currentUnit === "miles"
                ? lastPeriodBase?.avg500m /
                  CONSTANTS.UNIT_CONVERT.KILOMETER_CONVERT_TO_MILE_VALUE
                : lastPeriodBase?.avg500m,
                elevationGain:
        workoutState?.currentUnit === "miles"
            ? convertMeterToFit(lastPeriodBase?.elevationGain)
            : Math.floor(lastPeriodBase?.elevationGain),
    };
    const { valueApi } = selected;
    const windowSize = useWindowSize();
    const EMPTY_VALUE = "---";
    const getLongTextClassName = (value: any, options?: any) => {
        if (value && value !== "") {
            if (options?.isTime) {
                const str = formatTime(Math.abs(value), true).toString();
                if (str.length >= 9) {
                    return "cellLongText";
                } else if (str.length > 11) {
                    return "cellLongText1";
                }
            } else {
                const str = String(value.toFixed(0));
                if (str.length >= 9) {
                    return "cellLongText";
                } else if (str.length > 11) {
                    return "cellLongText1";
                }
            }
        }
        return "";
    };

    const distanceCol = useMemo(() => {
        switch (workoutState.currentUnit) {
            case "miles":
                return { title: "MILES", key: "metter" };
            case "kilometers":
                return {
                    title: "KILOMETERS",
                    key: "metter",
                };
            default:
                return { title: "METERS", key: "metter" };
        }
    }, [workoutState.currentUnit]);

    const elevationGainCol = useMemo(() => {
        switch (workoutState.currentUnit) {
            case "miles":
                return {
                    title: (
                        <>
                            ELEVATION <br />
                            GAIN (FT)
                        </>
                    ),
                    key: "elevationGain",
                };
            case "kilometers":
                return {
                    title: (
                        <>
                            ELEVATION <br />
                            GAIN (M)
                        </>
                    ),
                    key: "elevationGain",
                };
            default:
                return {
                    title: (
                        <>
                            ELEVATION <br />
                            GAIN (M)
                        </>
                    ),
                    key: "elevationGain",
                };
        }
    }, [workoutState.currentUnit]);

    const avgSpeedCol = useMemo(() => {
        switch (workoutState.currentUnit) {
            case "miles":
                return { title: "AVG.MPH", key: "min500mAvg" };
            case "kilometers":
                return { title: "AVG. KPH", key: "min500mAvg" };
            default:
                return { title: "AVG.SPLIT", key: "min500mAvg" };
        }
    }, [workoutState.currentUnit]);

    const convertDistance = (value: number) => {
        switch (workoutState.currentUnit) {
            case "miles":
                return convertMeterToMile(value, 100, true) || 0;
            case "kilometers":
                return convertMeterToKilometer(value, 100, true) || 0;
            default:
                return value?.toFixed(0) || 0;
        }
    };
    const getAvgMphCellValue = (value: number) => {
        switch (workoutState.currentUnit) {
            case "miles":
                return convertKilometerToMile(value, 10) || 0;
            case "kilometers":
                return (Math.floor(value * 10) / 10 || 0).toLocaleString("en", {
                    minimumFractionDigits: 1,
                });
            default:
                return value?.toFixed(0) || 0;
        }
    };
    const isAvironBike = Number(appId) === CONSTANTS.APP.AVIRON_BIKE;

    const columns = [
        { title: "STATS", key: "headerRow" },
        { title: "Workouts", key: "workout" },
        { title: "Time", key: "time" },
        { title: "OUTPUT(KJ)", key: "output" },
        { title: "AVG.WATT", key: "wattAvg" },
        { title: "CALORIES", key: "calories" },
        distanceCol,
        elevationGainCol,
        {
            title: (
                <>
                    AVG. INCLINE <br />
                    (%)
                </>
            ),
            key: "avgIncline",
        },
        avgSpeedCol,
        { title: "AVG.PACE", key: "paceAvg" },
    ].filter(Boolean);

    const columnsMobile = [
        { title: "STATS", key: "headerRow" },
        {
            title: nameHeaderRow[0],
            key: "thisPeriod",
            className: "lastDayCell",
        },
        { title: "DIFFERENCE", key: "diffPeriod" },
        {
            title: nameHeaderRow[1],
            key: "lastPeriod",
            className: "previousDayCell",
        },
    ];
    const renderDifferentCell = (value: any, options?: any) => {
        if (value) {
            return (
                <div className={style["diffenrentCell"]}>
                    {value !== 0 && (
                        <div
                            className={
                                value > 0
                                    ? style["upValue"]
                                    : style["downValue"]
                            }
                        >
                            {options?.isTime
                                ? options.hideHours
                                    ? formatTime(Math.abs(value), false)
                                    : formatTime(Math.abs(value), true)
                                : Math.abs(value)}
                        </div>
                    )}
                    <div className={style["rightIcon"]}>
                        {value > 0 && (
                            <div className={style["upValue"]}>
                                <ArrowDropUpIcon />
                            </div>
                        )}
                        {value < 0 && (
                            <div className={style["downValue"]}>
                                <ArrowDropDownIcon />
                            </div>
                        )}
                        {value === 0 && "--"}
                    </div>
                </div>
            );
        } else {
            return EMPTY_VALUE;
        }
    };
    const renderDifferentAvgMph = (value: number) => {
        if (value) {
            return (
                <div className={style["diffenrentCell"]}>
                    {value !== 0 && (
                        <div
                            className={
                                value > 0
                                    ? style["upValue"]
                                    : style["downValue"]
                            }
                        >
                            {getAvgMphCellValue(Math.abs(value))}
                        </div>
                    )}
                    <div className={style["rightIcon"]}>
                        {value > 0 && (
                            <div className={style["upValue"]}>
                                <ArrowDropUpIcon />
                            </div>
                        )}
                        {value < 0 && (
                            <div className={style["downValue"]}>
                                <ArrowDropDownIcon />
                            </div>
                        )}
                        {value === 0 && "--"}
                    </div>
                </div>
            );
        } else {
            return EMPTY_VALUE;
        }
    };
    const renderDifferentDistance = (value: any, options?: any) => {
        if (value) {
            return (
                <div className={style["diffenrentCell"]}>
                    {value !== 0 && (
                        <div
                            className={
                                value > 0
                                    ? style["upValue"]
                                    : style["downValue"]
                            }
                        >
                            {convertDistance(Math.abs(value))}
                        </div>
                    )}
                    <div className={style["rightIcon"]}>
                        {value > 0 && (
                            <div className={style["upValue"]}>
                                <ArrowDropUpIcon />
                            </div>
                        )}
                        {value < 0 && (
                            <div className={style["downValue"]}>
                                <ArrowDropDownIcon />
                            </div>
                        )}
                        {value === 0 && "--"}
                    </div>
                </div>
            );
        } else {
            return EMPTY_VALUE;
        }
    };
    const renderAvgPaceDifferentCell = (value: any) => {
        if (value) {
            return (
                <div className={style["diffenrentCell"]}>
                    {value !== 0 && (
                        <div
                            className={
                                value > 0
                                    ? style["upValue"]
                                    : style["downValue"]
                            }
                        >
                            {convertedSecondToTime(Math.abs(value))}
                        </div>
                    )}
                    <div className={style["rightIcon"]}>
                        {value > 0 && (
                            <div className={style["upValue"]}>
                                <ArrowDropUpIcon />
                            </div>
                        )}
                        {value < 0 && (
                            <div className={style["downValue"]}>
                                <ArrowDropDownIcon />
                            </div>
                        )}
                        {value === 0 && "--"}
                    </div>
                </div>
            );
        } else {
            return EMPTY_VALUE;
        }
    };
    const rowsData = [
        {
            headerRow: {
                value: nameHeaderRow[0],
                key: "lastWeek",
                className: "header2",
            },
            workout: {
                value: thisPeriod?.workouts?.toFixed(0) || EMPTY_VALUE,
                key: "lastWeek",
                className: getLongTextClassName(thisPeriod?.workouts),
            },
            time: {
                value: formatTime(thisPeriod?.time, true) || EMPTY_VALUE,
                key: "lastWeek",
                className: getLongTextClassName(thisPeriod?.time, {
                    isTime: true,
                }),
            },
            output: {
                value: thisPeriod?.outputs?.toFixed(0) || EMPTY_VALUE,
                key: "lastWeek",
                className: getLongTextClassName(thisPeriod?.outputs),
            },
            wattAvg: {
                value: thisPeriod?.avgWatt?.toFixed(0) || EMPTY_VALUE,
                key: "lastWeek",
                className: getLongTextClassName(thisPeriod?.avgWatt),
            },
            calories: {
                value: thisPeriod?.calories?.toFixed(0) || EMPTY_VALUE,
                key: "lastWeek",
                className: getLongTextClassName(thisPeriod?.calories),
            },
            metter: {
                value: convertDistance(thisPeriod?.meters) || 0,
                key: "lastWeek",
                className: getLongTextClassName(thisPeriod?.meters),
            },
            elevationGain: {
                value: thisPeriod?.elevationGain || EMPTY_VALUE,
                key: "lastWeek",
                className: getLongTextClassName(thisPeriod?.elevationGain),
            },
            avgIncline: {
                value: thisPeriod?.avgIncline?.toFixed(0) || EMPTY_VALUE,
                key: "lastWeek",
                className: getLongTextClassName(thisPeriod?.avgIncline),
            },
            min500mAvg: {
                value: getAvgMphCellValue(thisPeriod?.avgSpeed || 0) || 0,
                key: "lastWeek",
                className: getLongTextClassName(thisPeriod?.avgSpeed),
            },
            paceAvg: {
                value:
                    convertedSecondToTime(thisPeriod?.avg500m) || EMPTY_VALUE,
                key: "lastWeek",
                className: getLongTextClassName(thisPeriod?.avg500m),
            },
        },
        {
            headerRow: {
                value: "DIFFERENCE",
                key: "different",
                className: "header2",
            },
            workout: {
                value: renderDifferentCell(different?.workouts),
                key: "different",
                className: getLongTextClassName(different?.workouts),
            },
            time: {
                value: renderDifferentCell(different?.time, { isTime: true }),
                key: "different",
                className: getLongTextClassName(different?.time, {
                    isTime: true,
                }),
            },
            output: {
                value: renderDifferentCell(different?.outputs || 0),
                key: "different",
                className: getLongTextClassName(different?.outputs),
            },
            wattAvg: {
                value: renderDifferentCell(different?.avgWatt || 0),
                key: "different",
                className: getLongTextClassName(different?.avgWatt),
            },
            calories: {
                value: renderDifferentCell(different?.calories || 0),
                key: "different",
                className: getLongTextClassName(different?.calories),
            },
            metter: {
                value: renderDifferentDistance(different?.meters || 0),
                key: "different",
                className: getLongTextClassName(different?.meters),
            },
            elevationGain: {
                value: renderDifferentCell(different?.elevationGain || 0),
                key: "lastWeek",
                className: getLongTextClassName(different?.elevationGain),
            },
            avgIncline: {
                value: renderDifferentCell(different && different?.avgIncline),
                key: "different",
                className: getLongTextClassName(different?.avgIncline),
            },
            min500mAvg: {
                value: renderDifferentAvgMph(different?.avgSpeed || 0),
                key: "different",
                className: getLongTextClassName(different?.avg500m, {
                    isTime: true,
                }),
            },
            paceAvg: {
                value: renderAvgPaceDifferentCell(
                    different && different?.avg500m
                ),
                key: "different",
                className: getLongTextClassName(different?.avg500m),
            },
        },
        {
            headerRow: {
                value: nameHeaderRow[1],
                key: "thisWeek",
                className: `header2 ${
                    valueApi !== "today" && "headerPreviousDay"
                }`,
            },
            workout: {
                value: lastPeriod?.workouts?.toFixed(0) || EMPTY_VALUE,
                key: "thisWeek",
                className: getLongTextClassName(lastPeriod?.workouts),
            },
            time: {
                value: formatTime(lastPeriod?.time, true) || EMPTY_VALUE,
                key: "thisWeek",
                className: getLongTextClassName(lastPeriod?.time, {
                    isTime: true,
                }),
            },
            output: {
                value: lastPeriod?.outputs?.toFixed(0) || EMPTY_VALUE,
                key: "thisWeek",
                className: getLongTextClassName(lastPeriod?.outputs),
            },
            wattAvg: {
                value: lastPeriod?.avgWatt?.toFixed(0) || EMPTY_VALUE,
                key: "thisWeek",
                className: getLongTextClassName(lastPeriod?.avgWatt),
            },
            calories: {
                value: lastPeriod?.calories?.toFixed(0) || EMPTY_VALUE,
                key: "thisWeek",
                className: getLongTextClassName(lastPeriod?.calories),
            },
            metter: {
                value: convertDistance(lastPeriod?.meters) || 0,
                key: "thisWeek",
                className: getLongTextClassName(lastPeriod?.meters),
            },
            elevationGain: {
                value: lastPeriod?.elevationGain?.toFixed(0) || EMPTY_VALUE,
                key: "thisWeek",
                className: getLongTextClassName(lastPeriod?.elevationGain),
            },
            avgIncline: {
                value: lastPeriod?.avgIncline?.toFixed(0) || EMPTY_VALUE,
                key: "thisWeek",
                className: getLongTextClassName(lastPeriod?.avgIncline),
            },
            min500mAvg: {
                value: getAvgMphCellValue(lastPeriod?.avgSpeed || 0) || 0,
                key: "thisWeek",
                className: getLongTextClassName(lastPeriod?.avgSpeed),
            },
            paceAvg: {
                value:
                    convertedSecondToTime(lastPeriod?.avg500m) || EMPTY_VALUE,
                key: "thisWeek",
                className: getLongTextClassName(lastPeriod?.avg500m),
            },
        },
    ];
    const rowDataMobile = [
        {
            headerRow: {
                value: "WORKOUTS",
                key: "headerRow",
                className: "header2",
            },
            thisPeriod: {
                value: thisPeriod?.workouts?.toFixed(0) || EMPTY_VALUE,
                key: "thisPeriod",
                className: getLongTextClassName(thisPeriod?.workouts),
            },
            diffPeriod: {
                value: renderDifferentCell(different?.workouts) || EMPTY_VALUE,
                key: "diffPeriod",
                className: getLongTextClassName(thisPeriod?.workouts),
            },
            lastPeriod: {
                value: lastPeriod?.workouts?.toFixed(0) || EMPTY_VALUE,
                key: "lastPeriod",
                className: getLongTextClassName(thisPeriod?.workouts),
            },
        },
        {
            headerRow: {
                value: "Time",
                key: "headerRow",
                className: "header2",
            },
            thisPeriod: {
                value: formatTime(thisPeriod?.time, true) || EMPTY_VALUE,
                key: "thisPeriod",
                className: getLongTextClassName(thisPeriod?.time, {
                    isTime: true,
                }),
            },
            diffPeriod: {
                value: renderDifferentCell(different?.time, { isTime: true }),
                key: "diffPeriod",
                className: getLongTextClassName(thisPeriod?.time, {
                    isTime: true,
                }),
            },
            lastPeriod: {
                value: formatTime(lastPeriod?.time, true) || EMPTY_VALUE,
                key: "lastPeriod",
                className: getLongTextClassName(thisPeriod?.time, {
                    isTime: true,
                }),
            },
        },
        {
            headerRow: {
                value: columns[3]?.title,
                key: "headerRow",
                className: "header2",
            },
            thisPeriod: {
                value: thisPeriod?.outputs?.toFixed(0) || EMPTY_VALUE,
                key: "thisPeriod",
                className: getLongTextClassName(thisPeriod?.outputs),
            },
            diffPeriod: {
                value: renderDifferentCell(different?.outputs) || EMPTY_VALUE,
                key: "diffPeriod",
                className: getLongTextClassName(thisPeriod?.outputs),
            },
            lastPeriod: {
                value: lastPeriod?.outputs?.toFixed(0) || EMPTY_VALUE,
                key: "lastPeriod",
                className: getLongTextClassName(thisPeriod?.outputs),
            },
        },
        {
            headerRow: {
                value: columns[4]?.title,
                key: "headerRow",
                className: "header2",
            },
            thisPeriod: {
                value: thisPeriod?.avgWatt?.toFixed(0) || EMPTY_VALUE,
                key: "thisPeriod",
                className: getLongTextClassName(thisPeriod?.avgWatt),
            },
            diffPeriod: {
                value: renderDifferentCell(different?.avgWatt) || EMPTY_VALUE,
                key: "diffPeriod",
                className: getLongTextClassName(thisPeriod?.avgWatt),
            },
            lastPeriod: {
                value: lastPeriod?.avgWatt?.toFixed(0) || EMPTY_VALUE,
                key: "lastPeriod",
                className: getLongTextClassName(thisPeriod?.avgWatt),
            },
        },
        {
            headerRow: {
                value: columns[5]?.title,
                key: "headerRow",
                className: "header2",
            },
            thisPeriod: {
                value: thisPeriod?.calories?.toFixed(0) || EMPTY_VALUE,
                key: "thisPeriod",
                className: getLongTextClassName(thisPeriod?.calories),
            },
            diffPeriod: {
                value: renderDifferentCell(different?.calories) || EMPTY_VALUE,
                key: "diffPeriod",
                className: getLongTextClassName(thisPeriod?.calories),
            },
            lastPeriod: {
                value: lastPeriod?.calories?.toFixed(0) || EMPTY_VALUE,
                key: "lastPeriod",
                className: getLongTextClassName(thisPeriod?.calories),
            },
        },
        {
            headerRow: {
                value: columns[6]?.title,
                key: "headerRow",
                className: "header2",
            },
            thisPeriod: {
                value: convertDistance(thisPeriod?.meters) || 0,
                key: "thisPeriod",
                className: getLongTextClassName(thisPeriod?.meters),
            },
            diffPeriod: {
                value: renderDifferentDistance(different?.meters || 0),
                key: "diffPeriod",
                className: getLongTextClassName(thisPeriod?.meters),
            },
            lastPeriod: {
                value: convertDistance(lastPeriod?.meters) || 0,
                key: "lastPeriod",
                className: getLongTextClassName(thisPeriod?.meters),
            },
        },
        {
            headerRow: {
                value: columns[7]?.title,
                key: "headerRow",
                className: "header2",
            },
            thisPeriod: {
                value: thisPeriod?.elevationGain || 0,
                key: "thisPeriod",
                className: getLongTextClassName(thisPeriod?.elevationGain),
            },
            diffPeriod: {
                value:
                    renderDifferentCell(different?.elevationGain) ||
                    EMPTY_VALUE,
                key: "diffPeriod",
                className: getLongTextClassName(thisPeriod?.elevationGain),
            },
            lastPeriod: {
                value: lastPeriod?.elevationGain,
                key: "lastPeriod",
                className: getLongTextClassName(thisPeriod?.elevationGain),
            },
        },
        {
            headerRow: {
                value: columns[8]?.title,
                key: "headerRow",
                className: "header2",
            },
            thisPeriod: {
                value: thisPeriod?.avgIncline?.toFixed(0) || EMPTY_VALUE,
                key: "thisPeriod",
                className: getLongTextClassName(thisPeriod?.avgIncline),
            },
            diffPeriod: {
                value:
                    renderDifferentCell(different?.avgIncline) || EMPTY_VALUE,
                key: "diffPeriod",
                className: getLongTextClassName(thisPeriod?.avgIncline),
            },
            lastPeriod: {
                value: lastPeriod?.avgIncline?.toFixed(0) || EMPTY_VALUE,
                key: "lastPeriod",
                className: getLongTextClassName(thisPeriod?.avgIncline),
            },
        },
        {
            headerRow: {
                value: columns[9]?.title,
                key: "headerRow",
                className: "header2",
            },
            thisPeriod: {
                value: getAvgMphCellValue(thisPeriod?.avgSpeed || 0),
                key: "thisPeriod",
                className: getLongTextClassName(thisPeriod?.avgSpeed),
            },
            diffPeriod: {
                value: renderDifferentAvgMph(different?.avgSpeed || 0),
                key: "diffPeriod",
                className: getLongTextClassName(thisPeriod?.avgSpeed),
            },
            lastPeriod: {
                value: getAvgMphCellValue(lastPeriod?.avgSpeed || 0) || 0,
                key: "lastPeriod",
                className: getLongTextClassName(thisPeriod?.avgSpeed),
            },
        },
        {
            headerRow: {
                value: columns[10]?.title,
                key: "headerRow",
                className: "header2",
            },
            thisPeriod: {
                value: convertedSecondToTime(thisPeriod?.avg500m),
                key: "thisPeriod",
                className: getLongTextClassName(thisPeriod?.avg500m),
            },
            diffPeriod: {
                value: renderAvgPaceDifferentCell(different?.avg500m),
                key: "diffPeriod",
                className: getLongTextClassName(thisPeriod?.avg500m),
            },
            lastPeriod: {
                value: convertedSecondToTime(lastPeriod?.avg500m),
                key: "lastPeriod",
                className: getLongTextClassName(thisPeriod?.avg500m),
            },
        },
    ];
    return (
        <div className={style["CompareTableWrapper"]}>
            {windowSize?.width < 1024 && (
                <Table rowsData={rowDataMobile} columns={columnsMobile} />
            )}
            {windowSize?.width >= 1024 && (
                <Table rowsData={rowsData} columns={columns as any[]} />
            )}
        </div>
    );
};

export default AvironBikeCompareTable;
