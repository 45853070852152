export enum AvironProductTypes {
    MACHINE = "machine",
    FITPLAN_MACHINE = "fitplan-machine",
    BIKE_MACHINE = "bike-machine",
    BIKE_SUB_MEMBERSHIP = "bike-subproduct-membership",
    BIKE_MEMBERSHIP = "bike-membership",
    SUB_MEMBERSHIP = "subproduct-membership",
    MEMBERSHIP = "membership",
    ACCESSORY = "accessory",
    WARRANTY = "warranty",
    GO_WARRANTY = "go-warranty",
    GO_MACHINE = "go-machine",
    GO_MEMBERSHIP = "go-membership",
    GO_SUB_MEMBERSHIP = "go-subproduct-membership",
    STRONG_UPGRADE_ITEM = "strong-upgrade-item",
    SHIPPING_SERVICE = "shipping-service",
    SURCHARGE_ITEM = "surcharge-item",
}