import { CONSTANTS } from "consts";

const renderZero = (base100: number) => {
    const base100Str = String(base100)
    let res = '0.';
    for(let i = 0; i < base100Str.length - 1; i++) {
        res = res + '0'
    }
    return res;
}
export const convertMeterToMile = (
    meter: number,
    base100: number = 100,
    isRound = false,
    keepOrigin = false
) => {
    if (typeof meter === 'number') {
        if (keepOrigin) {
            return meter / CONSTANTS.UNIT_CONVERT.MILE_TO_METER;
        }
        if (isRound) {
            return (
                Math.round(
                    (meter / CONSTANTS.UNIT_CONVERT.MILE_TO_METER) * base100
                ) / base100
            ).toLocaleString("en", {
                minimumFractionDigits: String(base100).length - 1,
            });
        }
        return (
            Math.floor(
                (meter / CONSTANTS.UNIT_CONVERT.MILE_TO_METER) * base100
            ) / base100
        ).toLocaleString("en", {
            minimumFractionDigits: String(base100).length - 1,
        });
    }
    return renderZero(base100);
};

export const convertMeterToKilometer = (
    meter: number,
    base100: number = 100,
    isRound = false,
    keepOrigin = false
) => {
    if (typeof meter === 'number') {
        if (keepOrigin) {
            return meter / CONSTANTS.UNIT_CONVERT.KILOMETER_TO_METER;
        }
        if (isRound) {
            return (
                Math.round(
                    (meter / CONSTANTS.UNIT_CONVERT.KILOMETER_TO_METER) *
                        base100
                ) / base100
            ).toLocaleString("en", {
                minimumFractionDigits: String(base100).length - 1,
            });
        }
        return (
            Math.floor(
                (meter / CONSTANTS.UNIT_CONVERT.KILOMETER_TO_METER) * base100
            ) / base100
        ).toLocaleString("en", {
            minimumFractionDigits: String(base100).length - 1,
        });
    } else return renderZero(base100);
};

export const convertKilometerToMile = (
    kilometer: number,
    base100: number = 100,
    isRound = false,
    keepOrigin = false
) => {
    if (typeof kilometer === 'number') {
        if (keepOrigin) {
            return (
                kilometer * CONSTANTS.UNIT_CONVERT.KILOMETER_CONVERT_TO_MILE_VALUE
            );
        }
        if (isRound) {
            return (
                Math.round(
                    kilometer *
                        CONSTANTS.UNIT_CONVERT.KILOMETER_CONVERT_TO_MILE_VALUE *
                        base100
                ) / base100
            ).toLocaleString("en", {
                minimumFractionDigits: String(base100).length - 1,
            });
        }
        return (
            Math.floor(
                kilometer *
                    CONSTANTS.UNIT_CONVERT.KILOMETER_CONVERT_TO_MILE_VALUE *
                    base100
            ) / base100
        ).toLocaleString("en", {
            minimumFractionDigits: String(base100).length - 1,
        });
    } else {
        return renderZero(base100);
    }
};

export const formatMeasureNumber = (number: number, numberDecimal=2) => {
    const options2 = { 
        style: 'currency', 
        currency: 'USD', 
        maximumFractionDigits: numberDecimal,
        roundingMode: "floor", 
    };
    //@ts-ignore
    const numberFormat2 = new Intl.NumberFormat('en-US', options2);
    return numberFormat2.format(number).replace('$', '').replace('.00', '')
}

export const convertMeterToFit = (meter: number) => {
    return Math.floor(meter * CONSTANTS.UNIT_CONVERT.METER_TO_FIT)
}
