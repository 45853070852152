const groupDevice: any = {
    apk: "AVIRON",
    bike: "AVIRON",
    tm: "AVIRON",
    c2: "CONCEPT 2",
};

export const handleFortmatGroupMachine = (groupName: string) => {
    return groupDevice[groupName];
};
